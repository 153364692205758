header {
  padding: 2rem 1rem;
  display: flex;
  .logo {
    flex-basis: 20%;
    a {
      display: flex;
      font-size:22px;
      text-align:left;
      text-decoration: none;
      color: $light-grey;
      font-weight:100;
      span {
        @include transition(all, 0.3s, ease);
        font-size: 94px;
        line-height: 0;
        vertical-align: inherit;
        transform: translate(-5px, -14px);
        width: 10px;
        color:#f76c6c;
      }
      &:hover {
        text-decoration: none;
        span {
          transform: translate(-5px, -20px);
        }

      }
    }
  }
}