body {font-size:16px;}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
}

.container {
  width: 100%;
  max-width:1280px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}
div[class^="col-"] {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}
.align-item-center {
  align-items: center;
}


//-------------------------------
// ------- RESPONSIVE -----------
//-------------------------------
@media all and (min-width:$xs){
  .row {
    flex-wrap: nowrap;
  }
  .col-50 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}
