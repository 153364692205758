.cards-wrapper {
  margin-top: 4em;}
.card-container {
  display: flex;
  flex-wrap: wrap;
  h2 {margin-bottom:.5em;}
  .col {flex-basis: 100%;}
  &__text {
    max-width:1080px;
    margin: 4em auto;
    h3 {
      font-size: 2rem;
      font-weight:100;
      margin: .5em auto;
    }
    .card {
      flex-basis: 33.333%;
      padding: 1em;
      &:before {display: none;}
    }
  }
}
.card {
  flex-basis: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: .5em;
}
.card-image {
  img {
    @include transition(all, 0.5s, ease);
    width: 100%;
    vertical-align: bottom;
    transform: scale(1,1);
  }
}
.card-text {
  position: absolute;
  width: 100%;
  z-index: 2;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  padding: 5px 10px;
  h3, p {
    @include transition(all, 0.3s, ease);
    color:#fff;
  }
  h3 {font-size:1rem;margin: 0;font-weight:600}
  p {font-size:.7rem; margin: 0;}
}

//-------------------------------
// ------- RESPONSIVE -----------
//-------------------------------
@media all and (min-width:$xxs){
  .card {
    flex-basis: 50%;
    margin-bottom: 0;
    &:before {
      @include transition(all, 0.3s, ease);
      content:'';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: transparent;
      z-index:1;
    }
    .card-text {
      @include transition(all, 0.3s, ease);
      background: none;
      bottom: -100%;
      opacity:0;
      h3, p {
        text-align: center;
        color: #fff;
        //opacity: 0;
      }
      h3 {font-size:2rem;}
      p {font-size:1.2rem;}
    }

    @include hover-style {
      &:before {
        background: rgba(0, 0, 0, 0.65);
      }
      .card-image {
        img {transform: scale(1.2,1.2);}
      }
      .card-text {
        opacity:1;
        @include center();
      }
    }
  }
}