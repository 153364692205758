.menu {
  font-family: 'Quicksand', sans-serif;
  flex-basis: 80%;
  text-align: right;

  a {
    color: #444;
    text-decoration: none;
    padding: 3px 10px;
    vertical-align: middle;
    position: relative;
    display: inline-block;
    &:after {
      display: block;
      content: '';
      border-bottom: solid 1px $primary;
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
      transform-origin: 100% 50%
    }

    @include hover-style {
      &:after {
        transform: scaleX(1);
        transform-origin: 0 50%;
      }
    }
  }
}
