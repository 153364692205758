h1, h2, h3, h4, h5, h6, p {
  color: $primary;
  margin-bottom: .5em;
}

h2 {
  font-size: 2.5rem;
}

//-------------------------------
// ------- RESPONSIVE -----------
//-------------------------------
@media all and (min-width:$xs) {
  h2 {
    font-size: 4rem;
  }
}