.image-text {
  margin: 3em auto;
  .image {
    margin-top: 1em;}
}

//-------------------------------
// ------- RESPONSIVE -----------
//-------------------------------
@media all and (min-width:$xxs){
  .image-text {
    .image {margin: 0;}
  }
}