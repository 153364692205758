footer {
  position: relative;
  color: $light-grey;
  background:#fff;
  width:100%;
  margin-top: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
}
.footer-link {
  color:$primary;
  padding: .5em 0;
}
.social-links {
  text-align:center;
  a {
    display: inline-block;
    padding: 6px 8px;
    vertical-align: middle;
    &:after {display:none;}
  }
  svg {
    width:28px;
    height: 28px;
  }
}