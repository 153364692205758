.text-center {text-align: center;}
a {
  color: #1f38ff;
  text-decoration: none;
  position: relative;
  display: inline-block;

  &:after {
    display: block;
    content: '';
    border-bottom: solid 1px #1f38ff;
    transform: scaleX(1);
    transition: transform 250ms ease-in-out;
    transform-origin: 0 50%
  }

  @include hover-style {
    &:after {
      transform: scaleX(0);
      transform-origin: 100% 50%;
    }
  }
}

//---- Screen Reader only
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}