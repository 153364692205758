.banner {
  background: #fff;
  height: auto;
  position: relative;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  h1 {
    font-family: 'Rozha One', serif;
    color:#34485d;
    font-size:4rem;
    line-height: 1.2em;
  }
  .subtitle {
    p {
      font-size:1.3rem;
      color:#555;
      font-weight:300;
    }
  }
}

//-------------------------------
// ------- RESPONSIVE -----------
//-------------------------------
@media all and (min-width:$xxs){
  .banner {
    height: 90vh;
    .inner-container {
      position: absolute;
      left:5vw;
      top: 17vh;
      padding: 1rem;
      width: 50%;
    }
    h1 {font-size:6rem;}
    .subtitle {
      position: absolute;
      padding: 1rem;
      top: 57vh;
      left:5vw;
      width: auto;
      p {
        font-size:2rem;
      }
    }
  }
}

@media all and (min-width:$xs){
  .banner {
    h1 {font-size:8rem;}
    .subtitle {width:50vw;}
  }
}

@media all and (min-width:$xs) and (max-height:$xs) {
  .banner {
    h1 {font-size:5rem;}
    .inner-container {top:10vh;}
    .subtitle {
      width:90vw;
      top: calc(10vh + 230px);
      p {font-size:1.5em;}
    }
  }
}

